<template>
  <div>
    <b-row>
      <b-col sm="12">
        <iq-card class="iq-card">
          <div class="iq-card-body profile-page p-0 profilePages">
            <div class="profile-header">
              <div class="cover-container" style="overflow:hidden;" >
                <img v-if="clubImgObj && clubImgObj[clubObj.club_id] && clubImgObj[clubObj.club_id]['cover_pic']" :src="clubImgObj[clubObj.club_id]['cover_pic']['image']" alt="profile-bg" class="rounded img-fluid" style="object-fit: contain; width: 100%;">
                <img v-else :src="vmBannerImage" alt="profile-bg" class="rounded img-fluid" style="object-fit: contain; width: 100%;">
                <ul class="header-nav d-flex flex-wrap justify-end p-0 m-0">
                  <li>
                    <a href="javascript:void(0);" v-if="clubObj && clubObj.club_id && clubAccess && clubAccess.club_edit" @click="goToClubEdit()" ><i class="ri-pencil-line"></i></a>
                  </li>
                </ul>
              </div>
              <div class="user-detail text-center mb-3">
                <div class="profile-img" >
                  <img v-if="clubImgObj && clubImgObj[clubObj.club_id] && clubImgObj[clubObj.club_id]['profile_pic']" :src="clubImgObj[clubObj.club_id]['profile_pic']['image_thumbnail']" alt="profile-img" class="rounded-circle" style="object-fit: contain; width:100px; height:100px;" />
                  <b-avatar v-else variant="primary" class="avatar-100 rounded" :text="getFirstLetterOfAString(clubObj.club_name)" style="border-radius: 100%!important;">
                  </b-avatar>
                </div>
                <div class="profile-detail">
                  <h3 class="">
                    {{clubObj.club_name}}
                  </h3>
                  <p>
                    <b-badge v-if="clubObj.is_gide_plus === 1" class="border mr-2 mb-2 font-weight-normal" variant="primary" style="font-weight: bold !important;">
                      Gide Plus
                    </b-badge>
                    <span class="mr-2 mb-2 font-weight-normal" variant="primary">
                      By {{clubObj.org_name ? clubObj.org_name : "Gide"}}
                    </span>
                  </p>
                </div>
                <div class="row postFollowCountDiv justify-content-center mt-3" v-if="clubSocialLinkObj && Object.keys(clubSocialLinkObj).length">
                  <div v-for="(item,index) in clubSocialLinkObj" :key="index">
                    <a :href="item" class="p-0 mr-3" target="_blank"><img :src="social[index]" class="img-fluid rounded" alt="facebook"></a>
                  </div>
                </div>
                <div class="row postFollowCountDiv mt-4">
                  <div class="col-4" v-for="(i,index) in socialInfo" :key="index">
                    <div class="" v-if="i.name === 'Follow'">
                      <button class="px-3 py-2 btn secondaryEffectBtn rounded" @click="followClub(clubFollowObj.followed_by_curr_user)">
                        <span>{{clubFollowObj.followed_by_curr_user===0?'Follow':'Unfollow'}}</span>
                      </button>
                    </div>
                    <div class="text-center pl-3 pr-3" v-else>
                      <h6>
                        {{i.name}}
                      </h6>
                      <p class="mb-0">
                        {{i.value}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </iq-card>
        <iq-card class="iq-card">
          <div class="iq-card-body p-0 showFlexListDiv">
            <div class="user-tabing showFlexList">
              <tab-nav :pills="true" id="pills-tab" class="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0 w-100">
                <div  class="flex-fill p-0" @click="loadFollowerData(false, 'feed')">
                  <tab-nav-items class="flex-fill p-0" :active="feedActive" id="pills-feed-tab" href="#profile-feed" ariaControls="pills-home" role="tab" :ariaSelected="feedActive" title="My Posts" />
                </div>
                <!-- <div  class="flex-fill p-0"  @click="loadFollowerData(false, 'about')">
                  <tab-nav-items class="flex-fill p-0"  :active="aboutActive" id="pills-activity-tab" href="#profile-activity" ariaControls="pills-profile" role="tab" :ariaSelected="aboutActive" title="About" />
                </div> -->
                <!-- <div  class="flex-fill p-0" @click="loadFollowerData(false, 'photo')">
                  <tab-nav-items  class="flex-fill p-0" :active="photoActive" id="pills-photo-tab"  href="#photoActive"  ariaControls="pills-photo" role="tab" :ariaSelected="photoActive" title="Photos" />
                </div> -->
                <!-- <div  class="flex-fill p-0" @click="loadFollowerData(false, 'video')">
                  <tab-nav-items  class="flex-fill p-0" :active="videoActive" id="pills-video-tab"  href="#videoActive"  ariaControls="pills-video" role="tab" :ariaSelected="videoActive" title="Videos" />
                </div> -->
                <div  class="flex-fill p-0" @click="loadFollowerData(true, 'follower')">
                  <tab-nav-items  class="flex-fill p-0" :active="followerActive" id="pills-friend-tab"  href="#profile-friends"  ariaControls="pills-contact" role="tab" :ariaSelected="followerActive" title="Followers" />
                </div>
              </tab-nav>
            </div>
          </div>
        </iq-card>
      </b-col>
      <b-col sm="12">
        <div class="tab-content">
          <tab-content-item :active="feedActive" id="profile-feed" aria-labelled-by="pills-feed-tab">
            <div class="iq-card-body p-0">
              <b-row>
                <!--
                <b-col lg="4">
                  <b-col lg="12" class="p-0">
                    <iq-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">
                          Photos
                        </h4>
                      </template>
                      <template v-slot:headerAction>
                        <p class="m-0 pointer">
                          <a @click="loadFollowerData(false, 'photo')"> View all
                          </a>
                        </p>
                      </template>
                      <template v-slot:body>
                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                          <li class="col-md-4 col-6 pl-2 pr-0 pb-3" v-for="(item,index) in clubImages" :key="index">
                            <a @click="showModelMedia = true; mediaIndex = index"><img :src="item.image" alt="gallary-image" class="img-fluid" /></a>
                          </li>
                        </ul>
                      </template>
                    </iq-card>
                    <iq-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">
                          Videos
                        </h4>
                      </template>
                      <template v-slot:headerAction>
                        <p class="m-0 pointer">
                          <a @click="loadFollowerData(false, 'video')">View All</a>
                        </p>
                      </template>
                      <template v-slot:body>
                        <ul class="profile-video-gallary d-flex flex-wrap p-0 m-0">
                          <li class="col-md-12 col-sm-12 pl-2 pr-0 pb-3 videoList" v-for="(item,index) in clubVideos" :key="index">
                            <iframe :src="item.embeded" class="rounded" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </li>
                        </ul>
                      </template>
                    </iq-card>
                  </b-col>
                </b-col>
                -->
                <b-col lg="12">
                  <div v-if="clubObj.club_id">
                    <PostAdd :propPostType="3" :propModuleObjId="clubObj.club_id" :propOpenedInModal="showModelProfileEdit"/>
                  </div>
                  <div id="post-modal-data" v-if="clubObj.club_id">
                    <PostList :propPostType="3" :propModuleId="clubObj.club_id"/>
                  </div>
                </b-col>
              </b-row>
            </div>
          </tab-content-item>
          <tab-content-item :active="aboutActive" id="profile-activity" aria-labelled-by="pills-activity-tab">
            <About :propClubObj="clubObj" />
          </tab-content-item>
          <tab-content-item :active="photoActive" id="profile-activity" aria-labelled-by="pills-activity-tab">
            <div class="iq-card">
              <div class="iq-card-body" v-if="clubObj.club_id && photoActive">
                <Photos :propModuleId="clubObj.club_id" :propShowAdd="clubAccess.club_edit" :propModuleType="'CLUB'" :propType="5" />
              </div>
            </div>
          </tab-content-item>
          <tab-content-item :active="videoActive" id="profile-activity" aria-labelled-by="pills-activity-tab">
            <div class="iq-card">
              <div class="iq-card-body" v-if="clubObj.club_id && videoActive">
                <Photos :propModuleId="clubObj.club_id" :propShowAdd="clubAccess && clubAccess.club_edit" :propModuleType="propModuleName" :propType="5" :propMediaType="'video'" />
              </div>
            </div>
          </tab-content-item>
          <tab-content-item :active="false" id="profile-friends" aria-labelled-by="pills-friend-tab" v-if="clubObj.club_id">
            <div class="iq-card">
              <div class="iq-card-body">
                <FollowerList :propFireApi="followerApiFire" :propModuleId="clubObj.club_id" :propFollowedByCurrUser="followStatusOfCurrUser" :key="clubObj.club_id"/>
              </div>
            </div>
          </tab-content-item>
        </div>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
    <b-modal
      v-model="showModelMedia"
      scrollable
      size="lg"
      hide-footer no-close-on-backdrop
      modal-class="mediaDialog">
      <MediaView :propMedia="clubImages" :propIndex="mediaIndex" />
    </b-modal>
  </div>
</template>
<script>
import { socialvue } from "../../../../config/pluginInit"
import About from "./ProfileFriends/About"
import PostList from "../../Post/PostList.vue"
import { Clubs } from "../../../../FackApi/api/club"
import { Follows } from "../../../../FackApi/api/follow"
import ApiResponse from "../../../../Utils/apiResponse.js"
import Utility from "../../../../Utils/utility"
import Photos from "../../Photos.vue"
import MediaView from "../../MediaView.vue"
import addImage from "/src/assets_gide/img/img/addPic.webp"
import FollowerList from "../../../../components/FollowerList.vue"
import PostAdd from "../../Post/PostAdd.vue"

export default {
  name: "Profile",
  components: {
    About,
    PostList,
    Photos,
    MediaView,
    FollowerList,
    PostAdd
  },
  data () {
    return {
      social: {
        "facebook": require("../../../../assets/images/icon/08.png"),
        "twitter": require("../../../../assets/images/icon/09.png"),
        "instagram": require("../../../../assets/images/icon/10.png"),
        "youtube": require("../../../../assets/images/icon/12.png"),
        "linkedin": require("../../../../assets/images/icon/13.png")
      },
      socialInfo: [
        {
          name: "Posts",
          value: 0
        },
        {
          name: "Follow"
        },
        {
          name: "Followers",
          value: 0
        }
      ],
      apiName: "club_view",
      photos: [],
      clubObj: {},
      clubFollowObj: {},
      vmFollowData: Object.assign({}, this.initFollowData()),
      clubAccess: {
        "club_edit": false
      },
      clubImgObj: {},
      clubSocialLinkObj: {},
      followStatusOfCurrUser: 0,
      followerApiFire: false,
      feedActive: true,
      aboutActive: false,
      followerActive: false,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Club Follow Response",
      propModuleName: "CLUB",
      vmBannerImage: Utility.getBgImageForLetter(this, "CLUB"),
      photoActive: false,
      clubImages: [],
      clubVideos: [],
      videoActive: false,
      showModelMedia: false,
      mediaIndex: 0,
      addImage: addImage,
      showModelProfileEdit: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  methods: {
    /**
     * initFollowData
     */
    initFollowData () {
      return {
        module_id: "",
        module_name: "",
        follow_status: 0
      }
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
      this.clubView()
    },
    /**
     * clubView
     */
    async clubView () {
      let clubId = this.$route.params.club_id
      try {
        let clubViewResp = await Clubs.clubView(this, clubId)
        if (clubViewResp && clubViewResp.resp_status) {
          this.clubObj = clubViewResp.resp_data.data
          this.setUserRoles()

          this.clubImages = clubViewResp.photos
          this.clubVideos = clubViewResp.videos

          this.vmBannerImage = Utility.getBgImageForLetter(this, this.clubObj.club_name)
          this.clubImgObj = clubViewResp.club_images

          if (clubViewResp.social_links && clubViewResp.social_links.social_links) {
            try {
              const newObj = JSON.parse(clubViewResp.social_links.social_links)
              let clubSocialLinkObj = { ...newObj }
              this.clubObj.clubSocialLinkObj = newObj
              delete clubSocialLinkObj.website
              for (let index in clubSocialLinkObj) {
                if (clubSocialLinkObj[index] && clubSocialLinkObj[index] !== "") {
                  this.clubSocialLinkObj[index] = clubSocialLinkObj[index]
                }
              }
            }
            catch (e) {
              console.error("Exception occurred at clubView() and Exception:", e.message)
            }
          }

          // club  follow
          this.clubFollowObj = clubViewResp.club_follow
          this.followStatusOfCurrUser = clubViewResp.club_follow.followed_by_curr_user
          if (clubViewResp.post_count) {
            this.socialInfo[0]["value"] = clubViewResp.post_count
          }
          if (clubViewResp.club_follow && clubViewResp.club_follow.follow_count) {
            this.socialInfo[2]["value"] = clubViewResp.club_follow.follow_count
          }
        }
        else {
          return false
        }
      }
      catch (err) {
        console.error("Exception occurred at clubView() and Exception:", err.message)
      }
    },
    /**
     * getUserRoles
     */
    setUserRoles () {
      let clubId = this.$route.params.club_id
      if (this.userData.user_role === "USERROLE11111" || this.userData.user_role === "USERROLE11118") {
        // Admin or Counsellor
        this.clubAccess.club_edit = true
      }
      else if (this.userData.user_role === "USERROLE11115" && this.userData.roles[this.clubObj.owner_module_obj_id]) {
        // Club's Org Admin
        this.clubAccess.club_edit = true
      }
      else if (this.userData && this.userData.roles && this.userData.roles[clubId] && this.userData.roles[clubId]["actions"]) {
        if (this.userData.roles[clubId].udr_name && this.userData.roles[clubId].udr_name.toLowerCase() === "admin") {
          this.clubAccess.club_edit = true
          return
        }
        let actions = this.userData.roles[clubId]["actions"]
        let clubEditAccess = actions.findIndex(obj => {
          return obj.api_name.toLowerCase() === "club_edit"
        })
        if (clubEditAccess > -1) {
          this.clubAccess.club_edit = true
        }
      }
    },
    /**
     * goToClubEdit
     */
    goToClubEdit () {
      try {
        if (this.clubObj && Object.keys(this.clubObj).length > 0) {
          this.$router.push("/club_edit/" + this.clubObj.club_id)
        }
      }
      catch (err) {
        console.error("Exception occurred at goToClubEdit() and Exception:", err.message)
      }
    },
    /**
     *  followClub ()
     */
    async followClub (followStatus) {
      try {
        let clubId = this.$route.params.club_id
        this.vmFollowData.module_id = clubId
        this.vmFollowData.module_name = "CLUB"
        this.vmFollowData.follow_status = followStatus ? 0 : 1
        let followResp = await Follows.followAdd(this, this.vmFollowData)
        await ApiResponse.responseMessageDisplay(this, followResp)
        if (followResp && !followResp.resp_status) {
          return
        }
        else {
          this.clubFollowObj.followed_by_curr_user = this.vmFollowData.follow_status
          this.followStatusOfCurrUser = this.vmFollowData.follow_status
          if (followStatus) {
            this.clubFollowObj.follow_count--
          }
          else {
            this.clubFollowObj.follow_count++
          }
          this.socialInfo[1].value = this.clubFollowObj.follow_count
          this.vmFollowData = Object.assign({}, this.initFollowData())
        }
      }
      catch (err) {
        console.error("Exception occured at followClub() and Exception", err.message)
      }
    },
    /**
     * loadFolloweData
     */
    loadFollowerData (bool, tabType) {
      try {
        this.followerApiFire = bool
        if (tabType === "feed") {
          this.feedActive = true
          this.aboutActive = false
          this.photoActive = false
          this.videoActive = false
          this.followerActive = false
        }
        else if (tabType === "about") {
          this.feedActive = false
          this.aboutActive = true
          this.photoActive = false
          this.videoActive = false
          this.followerActive = false
        }
        else if (tabType === "photo") {
          this.photoActive = true
          this.feedActive = false
          this.aboutActive = false
          this.followerActive = false
          this.videoActive = false
        }
        else if (tabType === "video") {
          this.photoActive = false
          this.feedActive = false
          this.aboutActive = false
          this.followerActive = false
          this.videoActive = true
        }
        else {
          this.feedActive = false
          this.videoActive = false
          this.aboutActive = false
          this.photoActive = false
          this.followerActive = true
        }
      }
      catch (err) {
        console.error("Exception occured at loadFollowerData() and Exception", err.message)
      }
    }
  }
}
</script>

<style>
  .pointer{
    cursor: pointer;
  }
</style>
<style lang="scss">
  .uploadImagesInput {
    position: absolute;
    width: 100%;
    margin-left: -90px;
    opacity: 0;
  }
  ul.profile-img-gallary {
    li {
      height: 100px;
      width: 100%;
      padding: 6px 3px !important;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
  .profile-video-gallary {
    li {
      width: 100%;
      padding: 6px 3px !important;
    }
  }
  .previewImages {
    img {
      background-attachment: fixed;
      opacity: 0.5;
      &:before{
        content: "Joe's Task: ";
      }
    }
  }
  .videoLinkList {
    li {
      overflow: hidden;
      word-break: break-all;
      .closeIcon {
        position: relative;
      }
    }
  }
  .user-images {
    height: 234px;
  }
  .videoList {
    // height: 150px;
    img, iframe {
      width: 100%;
      height: 230px;
      object-fit: cover;
    }
  }
</style>
