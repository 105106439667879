<template>
  <div class="row">
    <!-- <div class="col-md-3">
        <tab-nav :pills="true" id="tab" class="nav nav-pills basic-info-items list-inline d-block p-0 m-0 showHoriZon" >
          <tab-nav-items :active="true" id="pills-about-info" href="#about-info" ariaControls="pills-about-info" role="tab" :ariaSelected="true" title="Details" />
          <tab-nav-items :active="false" id="pills-contact-info" href="#contact-info" ariaControls="pills-contact-info" role="tab" :ariaSelected="true" title="Contact" />
        </tab-nav>
      </div> -->
    <div class="col-md-12">
      <div class="tab-content">
        <tab-content-item :active="true" id="about-info" aria-labelled-by="pills-about-info">
          <div class="iq-card">
            <div class="iq-card-body">
              <h4>
                About
              </h4>
              <hr />
              <!-- <p class="mb-2">{{propClubObj.club_desc}}</p> -->
              <p class="mb-2 vueHtmlParent">
                <span :class="propShowReadMore && showReadMore ? 'threeLineOnly':''" v-html="propClubObj.club_desc"></span>
                <span class="pl-2 pointer showMore" v-if="propShowReadMore && showReadMore">
                  <u><a @click="emitOpenAboutTab()">Show more</a></u>
                </span>
              </p>
            </div>
          </div>
        </tab-content-item>
        <tab-content-item :active="!propShowReadMore" v-if="propClubObj.clubSocialLinkObj && Object.keys(propClubObj.clubSocialLinkObj).length" id="contact-info" aria-labelled-by="pills-contact-info">
          <div class="iq-card">
            <div class="iq-card-body">
              <h4 class="mt-3">
                Websites and Social Links
              </h4>
              <hr>
              <div class="row" v-if="propClubObj.clubSocialLinkObj">
                <template v-for="(item, index) in propClubObj.clubSocialLinkObj">
                  <div class="col-3 capitalize pr-0" :key="index+'1'">
                    <h6>
                      {{index}}
                    </h6>
                  </div>
                  <div class="col-9" :key="index">
                    <p class="mb-0">
                      <a :href="item" target="_blank">{{item}}</a>
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </tab-content-item>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .showMore {
    position: absolute;
    margin-top: -26px;
    right: 25px;
    background: white;
  }
</style>
<script>
export default {
  name: "About",
  props: {
    propClubObj: {
      type: Object,
      default: function () {
        return {
          club_id: "",
          club_desc: "",
          club_name: "",
          follower_count: "",
          owner_id: "",
          owner_type: ""
        }
      }
    },
    propShowReadMore: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      description: "",
      showReadMore: false
    }
  },
  mounted () {
    if (this.propShowReadMore && this.propClubObj.club_desc && this.propClubObj.club_desc.length) {
      this.showReadMore = true
    }
  },
  methods: {
    /**
     * emitOpenAboutTab
     */
    emitOpenAboutTab () {
      this.$emit("emitOpenAboutTab", true)
    }
  }
}
</script>
